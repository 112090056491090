<template>
  <div id="pageComplain">
    <v-container grid-list-xl fluid>
      <v-card>
        <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>
          <!-- Danh sách sản phẩm -->
          <v-flex sm12 md12 lg8>
            <v-widget title="Danh sách sản phẩm khiếu nại">
              <div slot="widget-content">
                <v-data-table  :headers="productHeader" :items="products" :mobile-breakpoint="100">
                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td>
                        <a target="blank" :href="item.link_origin">{{ item.ID }}</a>
                      </td>
                      <td>
                        <img class="image-product" :src="item.image_origin && item.image_origin.includes('firebase') ? item.image_origin : decodeURIComponent(item.image_origin)" alt />
                      </td>
                      <td>{{ item.property }}</td>
                      <td class="text-end">{{ item.quantity }}</td>
                      <td class="text-end">{{ item.price_promotion | currency('¥', 2) }}</td>
                      <td
                        class="text-end"
                      >{{ item.quantity*item.price_promotion | currency('¥', 2) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-widget>
          </v-flex>
          <v-flex sm12 md12 lg4>
                <v-widget title="Chat box">
                    <div slot="widget-content">
                        <message-box :orderId="Number(complainId)" :orderType="4"></message-box>
                    </div>
                </v-widget>
          </v-flex>
          <v-flex sm12 md12 lg12>
            <v-widget title="Nội dung chi tiết">
            <template slot="widget-content">
              <v-layout row wrap>
                <v-flex lg6 md6 sm12>
                  <v-text-field
                    label="Khách hàng"
                    v-model="currentComplain.Username"
                    class="input-group--focused"
                    disabled
                  ></v-text-field>
                  <v-text-field
                    label="Mã đơn hàng"
                    v-model="currentComplain.OrderID"
                    class="input-group--focused"
                    disabled
                  ></v-text-field>
                  <h-currency-input
                    v-model="currentComplain.AmountNDT"
                    :disabled="currentComplain.Status === 3 || !adminRole"
                    :decimal="2"
                    label="Số tiền (¥)"
                    class="input-group--focused"
                    @input="currentComplain.Amount = currentComplain.AmountNDT * currentComplain.ExchangeRate"
                  ></h-currency-input>
                  <h-currency-input
                    :disabled="currentComplain.Status === 3 || !adminRole"
                    v-model="currentComplain.ExchangeRate"
                    :decimal="0"
                    label="Tỷ giá"
                    class="input-group--focused"
                    @input="currentComplain.Amount = currentComplain.AmountNDT * currentComplain.ExchangeRate"
                    :rules="requireRules"
                  ></h-currency-input>
                  <h-currency-input
                    disabled
                    v-model="currentComplain.Amount"
                    :decimal="0"
                    label="Số tiền (vnđ)"
                    class="input-group--focused"
                  ></h-currency-input>
                </v-flex>
                <v-flex lg6 md6 sm12>
                  <p>Nội dung khiếu nại</p>
                  <v-checkbox disabled label="Thiếu hàng" v-model="currentComplain.IsMissGood"></v-checkbox>
                  <v-checkbox disabled label="Hàng bị hỏng" v-model="currentComplain.IsBroken"></v-checkbox>
                  <v-checkbox
                    disabled
                    label="Sai tùy chọn (mầu, size)"
                    v-model="currentComplain.IsOption"
                  ></v-checkbox>
                  <v-checkbox disabled label="Khác" v-model="currentComplain.IsOther"></v-checkbox>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 pb-0>
                  <p>Hình ảnh</p>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 pt-0>
                  <gallery :images="images" :index="imgIndex" @close="imgIndex = null"></gallery>
                  <div
                    class="image"
                    v-for="(image, imageIndex) in images"
                    :key="imageIndex"
                    @click="imgIndex = imageIndex"
                    :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
                  ></div>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 pt-0>
                  <v-textarea
                    disabled
                    auto-grow
                    label="Nội dung"
                    v-model="currentComplain.ComplainText"
                    class="input-group--focused"
                  ></v-textarea>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 pt-0>
                  <v-textarea
                    label="Ghi chú của nhân viên"
                    v-model="currentComplain.NoteOfStaff"
                    class="input-group--focused"
                  ></v-textarea>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 pt-0>
                  <v-textarea
                    label="Ghi chú cho khách hàng"
                    v-model="currentComplain.NoteForUser"
                    class="input-group--focused"
                  ></v-textarea>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12 v-if="adminRole || purchaseRole">
                  <v-select
                    :disabled="currentComplain.Status === 3"
                    v-model="editStatus"
                    :items="adminRole ? complain_status_list_full : complain_status_list"
                    item-text="name"
                    item-value="value"
                  ></v-select>
                </v-flex>
                <v-layout class="d-flex">
                    <v-flex lg12 v-if="adminRole || purchaseRole">
                        <v-btn class="align-right" color="#dc3545" dark @click="saveData">
                            Lưu lại
                        </v-btn>
                    </v-flex>
                </v-layout>
              </v-layout>
            </template>
            </v-widget>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import VueGallery from "vue-gallery";
import VWidget from "@/components/VWidget";
import { sendErrorNotice } from "@/commons/utils";
import MessageBox from "@/components/chat/MessageBox";
export default {
  components: {
    gallery: VueGallery,
    VWidget,
    MessageBox
  },
  data() {
    return {
      requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
      complain_status: configs.COMPLAIN_STATUS,
      complain_status_list_full: configs.COMPLAIN_STATUS_LIST,
      complain_status_list: _.filter(configs.COMPLAIN_STATUS_LIST, v => {return v.value != 3;}) ,
      editStatus: 1,
      imgIndex: null,
      productHeader: [
        {
          text: "ID",
          value: "ID",
          sortable: false
        },
        {
          text: "Ảnh",
          value: "image_origin",
          sortable: false
        },
        {
          text: "Thuộc tính",
          value: "property",
          sortable: false
        },
        {
          text: "Số lượng",
          value: "quantity",
          align: "end",
          sortable: false
        },
        {
          text: "Giá (¥)",
          value: "price_promotion",
          align: "end",
          sortable: false
        },
        {
          text: "Thành tiền (¥)",
          value: "total",
          align: "end",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState("complain", {
      loading: "loading",
      currentComplain: "selected"
    }),
    ...mapState({
      products: state => state.complain.selected.Products
    }),
    complainId() {
      return this.$route.params.Pid;
    },
    images() {
      var listOfImg = this.currentComplain.IMG
        ? this.currentComplain.IMG.split(";")
        : [];
      for (let i = 0; i < listOfImg.length; i++) {
        listOfImg[i] = `${configs.SYSTEM_CONFIG.firebase_storage}/images%2Fcomplain%2F${listOfImg[i]}?alt=media`;
      }
      return listOfImg;
    },
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    },
    purchaseRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
    }
  },
  watch: {
    currentComplain() {
      this.editStatus = this.currentComplain.Status;
    }
  },
  methods: {
    refreshData() {
      if (this.complainId > 0) {
        this.$store.dispatch("complain/getDetail", this.complainId);
      }
    },
    saveData() {
      if(!this.adminRole && !this.purchaseRole) {
        sendErrorNotice('Bạn không có quyền sửa khiếu nại.')
        return;
      }
      this.currentComplain.Status = this.editStatus;
      this.$store.dispatch("complain/setDetail", this.currentComplain);
    }
  },
  mounted() {
    this.refreshData();
    this.$root.$emit('REQUEST_ROOT_OPTION', {});
    this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
  }
};
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.image-product {
  width: 64px;
}
.align-right{
  float: right;
  margin: 5px;
}
</style>
